// Charts
document.addEventListener("DOMContentLoaded", function () {
    if (window.am4core) {
        window.am4core.addLicense("CH286859962");
        window.am4core.addLicense("MP286859962");
    }
});
if (window.mapboxgl) {
    window.mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_PUBLIC_TOKEN;
}
