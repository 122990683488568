import "./bootstrap";

import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";
import focus from "@alpinejs/focus";
import mask from "@ryangjchandler/alpine-mask";
import ui from "@alpinejs/ui";

Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(mask);
Alpine.plugin(ui);

window.Alpine = Alpine;

Alpine.start();

/**
 * Added this addEventListener because of Google Lightspeed
 *
 * Test via a getter in the options object to see if the passive property is accessed
 */
document.addEventListener("touchstart", function () {}, { passive: true });
