function stripeModule() {
    this.init = function (stripe_key) {
        Livewire.on("addNewPaymentMethod", (postId) => {
            var stripe = Stripe(stripe_key);
            var elements = stripe.elements();

            var style = {
                base: {
                    color: "#32325d",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                    ":-webkit-autofill": {
                        color: "#32325d",
                    },
                },
                invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                    ":-webkit-autofill": {
                        color: "#fa755a",
                    },
                },
            };

            var options = {
                style: style,
                supportedCountries: ["SEPA"],
                placeholderCountry: "NL",
            };

            // Create an instance of the IBAN Element
            var iban = elements.create("iban", options);

            // Add an instance of the IBAN Element into the `iban-element` <div>
            iban.mount("#iban-element");

            var form = document.getElementById("payment-form");
            var accountholderName =
                document.getElementById("accountholder-name");
            var email = document.getElementById("email");
            var submitButton = document.getElementById("submit-button");
            var clientSecret = submitButton.dataset.secret;

            form.addEventListener("submit", function (event) {
                event.preventDefault();
                var response = stripe
                    .confirmSepaDebitSetup(clientSecret, {
                        payment_method: {
                            sepa_debit: iban,
                            billing_details: {
                                name: accountholderName.value,
                                email: email.value,
                            },
                        },
                    })
                    .then(function (response) {
                        Livewire.emit(
                            "addNewPaymentMethodToStripe",
                            response.setupIntent.payment_method
                        );
                    });
            });
        });
    };
}

// module.exports = stripeModule;
export default stripeModule;
