/**
 * Popup
 */
import { createPopper } from "@popperjs/core";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
window.tippy = tippy;
window.createPopper = createPopper;

/**
 * Stripe
 */
let stripeKey = import.meta.env.VITE_STRIPE_KEY;
import StripeModule from "./stripe";
let Stripe = new StripeModule();
Stripe.init(stripeKey);

/**
 * Carousel
 */
import Glide from "@glidejs/glide";
window.Glide = Glide;

/**
 * Progressbar
 */
import ProgressBar from "progressbar.js";
window.ProgressBar = ProgressBar;

/**
 * ArrowDisabler
 */
import ArrowDisabler from "./ArrowDisabler";
window.ArrowDisabler = ArrowDisabler;

window.progressCircleNew = function ({
    containerId,
    topText,
    middleText,
    bottomText,
    middleValue,
    textColor = "#3c413f",
    trailColor = "#7abb9f",
    progressColor = "#25a075",
    smallSize = true,
} = {}) {
    return window.progressCircle(
        containerId,
        topText,
        middleText,
        bottomText,
        middleValue,
        textColor,
        trailColor,
        progressColor,
        smallSize
    );
};

window.progressCircle = function (
    containerId,
    topText,
    middleText,
    bottomText,
    middleValue,
    textColor = "#3c413f",
    trailColor = "#7abb9f",
    progressColor = "#25a075",
    smallSize = true
) {
    var barText = setCirlceText(topText, middleText, bottomText);
    //Default options
    var strokeSize = 5;

    if (smallSize == false) {
        strokeSize = 4;
        trailColor = "#a5c9b4";
        progressColor = "#25a075";
    }
    // var textColor = "#3c413f";
    // var trailColor = "#7abb9f90";
    // var progressColor = "#24a17590";

    // var textColor = textColor;
    // var trailColor = trailColor;
    // var progressColor = progressColor;

    var textOptions = {
        autoStyleContainer: false,
        style: {
            color: textColor,
            lineHeight: "1.3rem",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontFamily: "Qanelas",
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            padding: 0,
            margin: 0,
            transform: {
                prefix: true,
                value: "translate(-50%, -50%)",
            },
        },
    };

    return new ProgressBar.Circle(containerId, {
        color: progressColor,
        trailColor: trailColor,
        strokeWidth: strokeSize,
        trailWidth: strokeSize,
        easing: "easeInOut",
        duration: 1400,
        text: textOptions,
        from: { color: trailColor, width: strokeSize },
        to: { color: progressColor, width: strokeSize },
        step: function (state, circle) {
            if (Math.round(circle.middleValue) > 0) {
                middleValue = Math.round(circle.middleValue);
            }
            circle.path.setAttribute("stroke-width", state.width);
            circle.setText(
                setCirlceText(
                    topText,
                    middleValue + middleText,
                    bottomText,
                    smallSize
                )
            );
        },
    });
};

var locale = document.getElementsByTagName("html")[0].getAttribute("lang");

window.setCirlceText = function (
    topText,
    middleText,
    bottomText,
    smallSize = true
) {
    let check_for_integer = parseInt(middleText);
    if (check_for_integer == middleText) {
        middleText = new Intl.NumberFormat(locale, {
            maximumFractionDigits: 0,
        }).format(middleText);
    }

    if (smallSize == false) {
        return (
            "<p class='md:text-[16px] pt-2 text-sm font-normal'>" +
            topText +
            "</p>" +
            "<p class='text-[20px] leading-none md:my-1 md:px-1 font-bold md:text-[32px]'>" +
            middleText +
            "</p>" +
            "<p class='md:text-[16px] text-sm font-normal'>" +
            bottomText +
            "</p>"
        );
    }

    return (
        "<p class='md:text-[14px] text-xs font-medium'>" +
        topText +
        "</p>" +
        "<p class='text-[16px] leading-none md:mt-0 md:px-1 font-bold md:text-[23px]'>" +
        middleText +
        "</p>" +
        "<p class='md:text-[14px] text-xs font-medium'>" +
        bottomText +
        "</p>"
    );
};

Livewire.on("progress-update", (treeProgress, trees) => {
    let text1 = document.getElementById("text1").innerHTML;
    let text3 = document.getElementById("text3").innerHTML;

    window.CO2Calc = progressCircle(
        progressCircleCO2,
        text1,
        "",
        text3,
        trees
    ).animate(treeProgress);
});
